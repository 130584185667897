import { Component, ReactNode } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import logo from "../../assets/images/logo_isac_navbar.svg";
import './navbar.css';

interface Props {
    transparent: boolean
}
export default class _Navbar extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    render(): ReactNode {
        return (

            <Navbar collapseOnSelect expand="lg" variant="dark" className={this.props.transparent ? "navbar navbar-expand-sm fixed-top navbar-light" : "navbar-color"}>
                <Navbar.Brand href="#home">
                    <img src={logo}
                        width='70%' height='70%'
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                    </Nav>
                    <Nav className='ms-auto'>
                        <Nav.Link className='text-white font-link' href="/home" >
                            HOME
                        </Nav.Link>
                        <Nav.Link className='text-white font-link' href="/council">
                            COUNCILS
                        </Nav.Link>
                        <Nav.Link className='text-white font-link' href="/standars">
                            STANDARS
                        </Nav.Link>
                        <Nav.Link className='text-white font-link' href="/contact">
                            CONTACT US
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>



        )
    }
}