import react, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import Navbar from '../../components/Navbar'
import './contact.css';
export default class Contact extends Component {


    render(): react.ReactNode {
        return (
            <div>
                <Navbar transparent={false} />
                <br />
                <b />
                <div className='form-class'>
                    <p className='text-center'>
                        <h3><b>Contact Us</b></h3>
                    </p>
                    <br />
                    <Form>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" />
                            <Form.Text className="text-muted">

                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>

                      

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={4} placeholder="Enter message" />
                        </Form.Group>

                        <Button className='btn-color' variant="primary" type="submit">
                            Send
                        </Button>
                    </Form>
                </div>
                <br />
                <p className='text-center'>
                    *If you are a new organization and you want more information about scuba standars contact us and join us free.
                </p>
            </div >
        )
    }
}