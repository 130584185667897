import { Component, ReactNode } from 'react';
import { Image } from 'react-bootstrap';
import Navbar from '../../components/Navbar';
import homeImg from '../../assets/images/portada.jpg';
import logo from '../../assets/images/logo_isac.svg';
import './home.css'
export default class Home extends Component {

    render(): ReactNode {
        return (
            <div id="dimScreen" className='fondo'>

                <Navbar transparent={true} />
                <div className='text-home text-center'>
                    <b>The International Scuba Scuba Agencies Council (ISAC) was created for the development of worldwide scuba training quality.
                        The establishment has the goal of recognize associations, implementing international standards.</b>
                    <br />
                    <div className=''>
                        <a href='/standars'>
                            <b>See more...</b></a>
                    </div>
                    <img className='imageHome'
                        src={logo}
                    />

                </div>
            </div>
        )
    }
}
