import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';


import Home from './sections/Home';
import Standars from './sections/Standars';
import Council from './sections/Council';
import Contact from './sections/Contact';


const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/home", element: <Home /> },
    { path: "/council", element: <Council /> },
    { path: "/standars", element: <Standars /> },
    { path: "/contact", element: <Contact /> }
  ]);
  return routes;
};

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
