import react, { Component } from 'react';
import { Image } from 'react-bootstrap';
import './council.css';
import Navbar from '../../components/Navbar';

import logoPDIC from '../../assets/images/logo-pdic.jpg';
import logoPADI from '../../assets/images/logo-padi.jpg';
import logoNaui from '../../assets/images/logo-naui.jpg';
import logoWRSTC from '../../assets/images/logo_wrstc.png';
import logoEUF from '../../assets/images/logo_euf.png';
import logoDAN from '../../assets/images/logo_dan.png';
import logoISAC from '../../assets/images/logo_isac_blue.png';
import logoADCI from '../../assets/images/logo_adci.png';
import logoCMAS from '../../assets/images/logo_cmas.png';
import logoIDSA from '../../assets/images/logo_idsa.png';
import logoIMCA from '../../assets/images/logo_imca.svg';
import logoSDI from '../../assets/images/logo_sdi.png';
import logoSSI from '../../assets/images/logo_ssi.png';
import logoVIT from '../../assets/images/logo_vit.png';
import logoACUC from '../../assets/images/logo_acuc.png';
import logoIANTD from '../../assets/images/logo_iantd.png';
import logoIAC from '../../assets/images/logo_iac.svg';
import logoIDA from '../../assets/images/logo_ida.png';
import logoIDDA from '../../assets/images/logo_idda.png';
import logoPDA from '../../assets/images/logo_pda.png';
import logoNASE from '../../assets/images/logo_nase.png';
import logoRAID from '../../assets/images/logo_raid.png';
import LogoGSA from '../../assets/images/gsa_logo.png';
import logoSNSI from '../../assets/images/logo_snsi.png';











export default class Council extends Component {


    render(): react.ReactNode {
        return (
            <div>
                <Navbar transparent={false} />

                <div className='text-center'>
                    <br />
                    <h3><b><u>Associations working to make scuba diving more safe</u></b></h3>
                    <br />

                    <div className='row text-center col-asso'>
                        <div className='col-md-3'>
                            <a href="https://wrstc.com/" target="_blank" rel="noreferrer">
                                <img
                                    src={logoWRSTC}
                                    width="70%"
                                />
                            </a>
                        </div>
                        <div className='col-md-3'>
                            <a href="https://euf.eu/" target="_blank" rel="noreferrer">
                                <img
                                    src={logoEUF}
                                    width="35%"
                                />
                            </a>
                        </div>
                        <div className='col-md-3'>
                            <a href="https://dan.org/" target="_blank" rel="noreferrer">
                                <img
                                    src={logoDAN}
                                    width="70%"
                                />
                            </a>
                        </div>
                        <div className='col-md-3'>
                            <a href="https://wrstc.com/" target="_blank" rel="noreferrer">
                                <img
                                    src={logoISAC}
                                    width="60%"
                                />
                            </a>
                        </div>

                    </div>
                    <br />

                    <div className='row text-center col-asso'>
                        <div className='col-md-3'>
                            <a href="https://www.adc-int.org/" target="_blank" rel="noreferrer">
                                <img
                                    src={logoADCI}
                                    width="30%"
                                />
                            </a>
                        </div>
                        <div className='col-md-3'>
                            <a href="https://www.cmas.org/" target="_blank" rel="noreferrer">
                                <img
                                    src={logoCMAS}
                                    width="35%"
                                />
                            </a>
                        </div>
                        <div className='col-md-3'>
                            <a href="https://www.idsaworldwide.org/" target="_blank" rel="noreferrer">
                                <img
                                    src={logoIDSA}
                                    width="40%"
                                />
                            </a>
                        </div>
                        <div className='col-md-3'>
                            <a href="https://www.imca-int.com/" target="_blank" rel="noreferrer">
                                <img
                                    src={logoIMCA}
                                    width="60%"
                                />
                            </a>
                        </div>

                    </div>

                </div>
                <div className='table-class'>
                    <br />
                    <br />
                    <br />
                    <p className='text-center'>
                        <h3><b>International Scuba Agencies Council recommends and recognizes the following associations:</b></h3>
                    </p>
                    <br />
                    <table className="table table-bordered">
                        <thead>

                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">

                                    <Image className='float-left' src={logoPADI}
                                        width='20%'
                                    />
                                    <div className='img-text text-center'>
                                        PADI – Professional Association of Diving Instructors
                                        <br />
                                        http://www.padi.com/ webmaster@padi.com
                                        <br />
                                        Address: 30151 Tomas Rancho Santa Margarita, CA 92688-2125
                                        <br />
                                        Phone: (800) 729-7234 / (949) 858-7234

                                    </div>

                                </th>
                            </tr>

                            <tr>
                                <th scope="row">

                                    <Image className='float-left' src={logoSDI}
                                        width='10%'
                                    />
                                    <div className='img-text text-center'>
                                        SDI – Scuba Diving International
                                        <br />
                                        https://www.tdisdi.com/
                                        <br />
                                        Address: 1321 SE Decker Ave Start, FL 34994 US
                                        <br />
                                        Phone: +1-207-7294-201 / +1-888-778-9073

                                    </div>

                                </th>
                            </tr>

                            <tr>
                                <th scope="row">

                                    <Image className='float-left' src={logoNaui}
                                        width='20%'
                                    />
                                    <div className='img-text text-center'>
                                        NAUI – National Association of Underwater Instructors
                                        <br />
                                        https://www.naui.org/ nauihq@naui.org
                                        <br />
                                        Address: 9030 Camden Field Parkway Riverview, FL 33578
                                        <br />
                                        Phone: (813) 628-6284

                                    </div>

                                </th>
                            </tr>

                            <tr>
                                <th scope="row">

                                    <Image className='float-left' src={logoSSI}
                                        width='15%'
                                    />
                                    <div className='img-text text-center'>
                                        SSI – Scuba Schools International
                                        <br />
                                        https://www.divessi.com/ usa@divessi.com
                                        <br />
                                        Address: 902 Clint Moore Rd. Suite 210 Boca Raton, FL 33487
                                        <br />
                                        Phone: +1 (0) 800 - 821-4319

                                    </div>

                                </th>
                            </tr>

                            <tr>
                                <th scope="row">
                                    <div className='text-center'>
                                        <Image className='float-left' src={logoACUC}
                                            width='20%'
                                        />
                                    </div>
                                    <div className='img-text text-center'>
                                        <b> ACUC – American Canadian Underwater Certifications</b>
                                        <br />
                                        https://acuc.es/en/ info@acucinternational.com
                                        <br />
                                        Address: Añastro, 25 28033 Madrid España
                                        <br />
                                        Phone:  +34 917668412

                                    </div>
                                </th>
                            </tr>

                            <tr>
                                <th scope="row">
                                    <div className='text-center'>
                                        <Image className='float-left' src={logoIANTD}
                                            width='13%'
                                        />
                                    </div>
                                    <div className='img-text text-center'>
                                        <b> IANTD – International Association of Nitrox and Technical Divers</b>
                                        <br />
                                        https://iantd.com/ iantd@iantd.com
                                        <br />
                                        Address: 119 NW Ethan Place, Lake City, Florida 32055
                                        <br />
                                        Phone:  +1 386 438-8312

                                    </div>
                                </th>
                            </tr>


                            <tr>
                                <th scope="row">
                                    <div className='text-center'>
                                        <Image className='float-left' src={logoIAC}
                                            width='13%'
                                        />
                                    </div>
                                    <div className='img-text text-center'>
                                        <b> IAC – International Aquanautic Club GmbH  Co. KG</b>
                                        <br />
                                        https://www.diveiac.com/  info@diveiac.com
                                        <br />
                                        Address: Frintroper Str. 1845355 Essen
                                        <br />
                                        Phone: +49(0)201-670049

                                    </div>
                                </th>
                            </tr>

                            <tr>
                                <th scope="row">
                                    <div className='text-center'>
                                        <Image className='float-left' src={logoIDA}
                                            width='20%'
                                        />
                                    </div>
                                    <div className='img-text text-center'>
                                        <b> IDA – International Diving Association</b>
                                        <br />
                                        https://www.ida-worldwide.com  k.reimer@ida-worldwide.com
                                        <br />
                                        Address: Dorfstraße 267 • 24222 Schwentinental
                                        <br />
                                        Phone: +49 (0)431 7992577

                                    </div>
                                </th>
                            </tr>

                            <tr>
                                <th scope="row">
                                    <div className='text-center'>
                                        <Image className='float-left' src={logoIDDA}
                                            width='20%'
                                        />
                                    </div>
                                    <div className='img-text text-center'>
                                        <b> IDDA – International Discovery Divers Association</b>
                                        <br />
                                        https://www.i-d-d-a.com/ info@i-d-d-a.com
                                        <br />
                                        Address: IDDA GmbH Twiedelftsweg 22 28279 Bremen Germany
                                        <br />
                                        Phone: +49 (0)421 / 84742354

                                    </div>
                                </th>
                            </tr>

                            <tr>
                                <th scope="row">
                                    <div className='text-center'>
                                        <Image className='float-left' src={logoPDA}
                                            width='15%'
                                        />
                                    </div>
                                    <div className='img-text text-center'>
                                        <b> PDA – Professional Diving Association</b>
                                        <br />
                                        https://pdascuba.com/ pdascuba@gmail.com
                                        <br />
                                        Address: El Aguila 23 Ciudad Evita. Provincia de Buenos Aires, Argentina.
                                        <br />
                                        Phone: +549 1140357805

                                    </div>
                                </th>
                            </tr>

                            <tr>
                                <th scope="row">
                                    <div className='text-center'>
                                        <Image className='float-left' src={logoVIT}
                                            width='30%'
                                            height='30%'
                                        />
                                    </div>
                                    <div className='img-text text-center'>
                                        <b> VIT – Verband Internationaler Tauchschulen</b>
                                        <br />
                                        https://www.vit.info https://en.vit.info/contact
                                        <br />
                                        Address: Bergackerweg 16a 93128 Regenstauf Deutschland
                                        <br />
                                        Phone:  +49 (0)700 848 55555

                                    </div>
                                </th>
                            </tr>


                            <tr>
                                <th scope="row">
                                    <div className='text-center'>
                                        <Image className='float-left' src={logoPDIC}
                                            width='30%'
                                            height='30%'
                                        />
                                    </div>
                                    <div className='img-text text-center'>
                                        <b> PDIC International – The Professional Diving Instructors Corporation</b>
                                        <br />
                                        https://www.scubaeducators.org info@scubaeducators.org
                                        <br />
                                        Address: 1301 Seminole Blvd Ste 175 Largo, FL 33770
                                        <br />
                                        Phone: (765) 281-0600

                                    </div>
                                </th>
                            </tr>

                            <tr>
                                <th scope="row">
                                    <div className='text-center'>
                                        <Image className='float-left' src={logoNASE}
                                            width='15%'
                                        />
                                    </div>
                                    <div className='img-text text-center'>
                                        <b> NASE – National Academy of SCUBA Educators</b>
                                        <br />
                                        https://www.naseworldwide.org/ info@info@naseworldwide.org
                                        <br />
                                        Address: PO Box 3166 Jacksonville, FL 32206
                                        <br />
                                        Phone: +1.888.903.NASE (6273)

                                    </div>
                                </th>
                            </tr>

                            <tr>
                                <th scope="row">
                                    <div className='text-center'>
                                        <Image className='float-left' src={logoRAID}
                                            width='20%'
                                        />
                                    </div>
                                    <div className='img-text text-center'>
                                        <b> RAID – Rebreather Association of International Divers</b>
                                        <br />
                                        https://www.diveraid.com/ info@diveraid.com
                                        <br />
                                        Address:29 Links Road Poolle Dorset BH14 9QS England
                                        <br />
                                        Phone: +63 9178475220

                                    </div>
                                </th>
                            </tr>


                            <tr>
                                <th scope="row">
                                    <div className='text-center'>
                                        <Image className='float-left' src={logoSNSI}
                                            width='20%'
                                        />
                                    </div>
                                    <div className='img-text text-center'>
                                        <b> SNSI – Scuba Nitrox Safety International</b>
                                        <br />
                                        https://scubasnsi.goscubasnsi.com/ https://scubasnsi.goscubasnsi.com/contattaci/
                                        <br />
                                        Address: 1669 SE. South Niemeyer Circle Suite #109 Port St. Lucie Florida
                                        <br />
                                        Phone:  1 772 777 4382

                                    </div>
                                </th>
                            </tr>

   <tr>
                                <th scope="row">
                                    <div className='text-center'>
                                        <Image className='float-left' src={LogoGSA}
                                            width='20%'
                                        />
                                    </div>
                                    <div className='img-text text-center'>
                                        <b> GSA – Global Scuba Association</b>
                                        <br />
                                        https://www.divegsa.com/ info@divegsa.com
                                       

                                    </div>
                                </th>
                            </tr>



                        </tbody>
                    </table>
                </div>
            </div >)

    }
}
