import react, { Component } from 'react';
import Navbar from '../../components/Navbar';
import logo_pdf from '../../assets/images/logo_pdf.png';
import './standars.css';


const data = [

    {
        'name': 'Open Water Diver',
        'link': "https://isac-pdfs.s3.amazonaws.com/open_water_diver.pdf",
        'date': ' 05/03/11 16:42:23',
        'description': 'Standards for Open Water Diver professional course.'

    },
    {
        'name': 'Advanced Diver',
        'link': '#',
        'date': ' 07/30/10 12:02:03',
        'description': 'Standards for Advance Diver professional course.'

    },
    {
        'name': 'Supervisor Diver',
        'link': 'https://isac-pdfs.s3.amazonaws.com/dive_supervisor.pdf',
        'date': ' 01/10/11 15:32:33',
        'description': 'Standards for Supervisor Diver professional course.'

    },
    {
        'name': 'Rescue Diver',
        'link': 'https://isac-pdfs.s3.amazonaws.com/rescue_diver.pdf',
        'date': ' 08/21/04 03:25:53',
        'description': 'Standards for Rescue Diver professional course.'

    },
    {
        'name': 'Scuba Equipment Repair',
        'link': '#',
        'date': ' 07/30/10 12:02:03',
        'description': 'Standards for Equipment Repair course.'

    },
    {
        'name': 'Dry Suit',
        'link': '#',
        'date': ' 07/30/10 12:02:03',
        'description': 'Standards for Dry suit diving.'
    },
    {
        'name': 'Nitrox Specialist',
        'link': '#',
        'date': ' 07/30/10 12:02:03',
        'description': 'Standards for Nitrox Specialist professional course.'
    },
    {
        'name': 'Decompressions diving',
        'link': '#',
        'date': ' 07/30/10 12:02:03',
        'description': 'Standards for Decompressions Theory professional course.'
    },
    {
        'name': 'Scuba Instructor',
        'link': 'https://isac-pdfs.s3.amazonaws.com/instructor_diver.pdf',
        'date': ' 02/22/07 13:34:03',
        'description': 'Standards for Scuba Instructor professional course.'
    },
    {
        'name': 'Scuba Instructor Trainer',
        'link': 'https://isac-pdfs.s3.amazonaws.com/instructor_trainer_diver.pdf',
        'date': ' 05/14/09 11:05:01',
        'description': 'Standards for Scuba Instructor Trainer professional course.'
    }

]

export default class Standars extends Component {

    render(): react.ReactNode {
        return (
            <div>
                <Navbar transparent={false} />
                <div className='table-class'>
                    <br />
                    <br />
                    <p className='text-center'>
                        <h3><b>Our Dive Standars:</b></h3>
                    </p>
                    <br />
                    <table className="table table-bordered">
                        <thead>
                            <tr className='text-center'>
                                <th scope="col"><b>NAME</b></th>
                                <th scope="col"><b>LAST UPDATE</b></th>
                                <th scope="col"><b>DESCRIPTION</b></th>
                                <th scope="col"><b>DOWNLOAD</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((d) => <tr>
                                <th scope="row">
                                    <div className='text-center'>
                                        <a href={d.link}> {d.name}</a>
                                    </div>
                                </th>
                                <th scope="row">
                                    <div className='text-center'>
                                        {d.date}
                                    </div>
                                </th>
                                <th scope="row">
                                    <div className='text-center'>
                                        {d.description}
                                    </div>
                                </th>
                                <th scope="row">
                                    <div className='text-center'>
                                        <a href={d.link}>  <img src={logo_pdf}
                                            width='10%'
                                        />
                                        </a>
                                    </div>
                                </th>
                            </tr>)}

                        </tbody>
                    </table>
                </div >
            </div>
        )
    }
}